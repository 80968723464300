body {
  background-color: #303030;
  color: #fff;
}

a {
  color: #007bff;
}

.bg-dark {
  background-color: #212121 !important;
}

.text-dark {
  color: #fff !important;
}

.text-muted {
  color: #9e9e9e !important;
}

.dropdown-menu {
  background-color: #343a40;
  color: #fff;
}

.dropdown-item {
  color: inherit;
}

.dropdown-item:hover, .dropdown-item.active {
  color: inherit;
  background-color: #4b545c;
}

.breadcrumb {
  background-color: #343a40;
}

.breadcrumb-item.active {
  color: #fff;
}

.nav-tabs {
  border-bottom-color: #454d55;
}

.nav-tabs .nav-link {
  color: #fff;
  background-color: #343a40;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link.active {
  color: inherit;
  background-color: #43494E;
  border-color: #454d55;
}

.card {
  background-color: #424242;
}

.card.bg-light {
  color: #383d41;
}

.modal-content {
  background-color: #424242;
}

.close {
  color: #fff;
}

.close:hover {
  color: #fff;
}

.jumbotron {
  background-color: #212121;
}

.form-control,
.form-control:active,
.form-control:focus {
  border-color: #454d55;
  background-color: #343a40;
  color: #fff;
  /*Change text in autofill textbox */
}

.form-control:-webkit-autofill,
.form-control:active:-webkit-autofill,
.form-control:focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #343a40 inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #fff;
}

.form-control::-webkit-input-placeholder,
.form-control:active::-webkit-input-placeholder,
.form-control:focus::-webkit-input-placeholder {
  color: #888;
}

.form-control:-ms-input-placeholder,
.form-control:active:-ms-input-placeholder,
.form-control:focus:-ms-input-placeholder {
  color: #888;
}

.form-control::-ms-input-placeholder,
.form-control:active::-ms-input-placeholder,
.form-control:focus::-ms-input-placeholder {
  color: #888;
}

.form-control::placeholder,
.form-control:active::placeholder,
.form-control:focus::placeholder {
  color: #888;
}

.custom-select {
  border-color: #454d55;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #343a40;
  color: #fff;
}

.custom-control-label::before {
  background-color: #343a40;
}

.input-group-text {
  border-color: #454d55;
  background-color: #4b545c;
  color: #fff;
}

.list-group-item {
  background-color: #343a40;
}

.list-group-item-action {
  color: inherit;
}

.list-group-item-action:not(.active):hover {
  color: inherit;
  background-color: #43494E;
}

.list-group-item-action.disabled {
  background-color: #343a40;
}

.page-link {
  background-color: #343a40;
  border-color: #454d55;
}

.page-link:hover {
  border-color: #454d55;
  background-color: #43494E;
}

.page-item.disabled .page-link {
  background-color: #343a40;
  border-color: #454d55;
}

.progress {
  background-color: #343a40;
}
