$bd-element-bg: #343a40 !default;
$bd-element-bg-hover: #43494E !default;
$bd-element-border-color: #454d55 !default;
$bd-card-bg: #424242 !default;
$bd-modal-content-bg: #424242 !default;
$bd-jumbotron-bg: #212121 !default;

body {
    background-color: #303030;
    color: #fff;
}

a {
    color: #007bff;
}

.bg-dark {
    background-color: #212121 !important;
}

.text-dark {
    color: #fff !important;
}

.text-muted {
    color: #9e9e9e !important;
}

.dropdown-menu {
    background-color: $bd-element-bg;
    color: #fff;
}

.dropdown-item {
    color: inherit;

    &:hover,
    &.active {
        color: inherit;
        background-color: lighten($bd-element-bg, 10%);
    }
}

.breadcrumb {
    background-color: $bd-element-bg;
}

.breadcrumb-item {
    &.active {
        color: #fff;
    }
}

.nav-tabs {
    border-bottom-color: $bd-element-border-color;

    .nav-link {
        color: #fff;
        background-color: $bd-element-bg;

        &:hover,
        &.active {
            color: inherit;
            background-color: $bd-element-bg-hover;
            border-color: $bd-element-border-color;
        }
    }
}

.card {
    background-color: $bd-card-bg;

    &.bg-light {
        color: #383d41;
    }
}

.modal-content {
    background-color: $bd-modal-content-bg;
}

.close {
    color: #fff;

    &:hover {
        color: #fff;
    }
}

.jumbotron {
    background-color: $bd-jumbotron-bg;
}

.form-control,
.form-control:active,
.form-control:focus {
    border-color: $bd-element-border-color;
    background-color: $bd-element-bg;
    color: #fff;

    /*Change text in autofill textbox */
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $bd-element-bg inset;
        /* Change the color to your own background color */
        -webkit-text-fill-color: #fff;
    }

    &::placeholder {
        color: #888;
    }
}

.custom-select {
    border-color: $bd-element-border-color;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    background-color: $bd-element-bg;
    color: #fff;
}

.custom-control-label {
    &::before {
        background-color: $bd-element-bg;
    }
}

.input-group-text {
    border-color: $bd-element-border-color;
    background-color: lighten($bd-element-bg, 10%);
    color: #fff;
}

.list-group-item {
    background-color: $bd-element-bg;
}

.list-group-item-action {
    color: inherit;

    &:not(.active):hover {
        color: inherit;
        background-color: $bd-element-bg-hover;
    }

    &.disabled {
        background-color: $bd-element-bg;
    }
}

.page-link {
    background-color: $bd-element-bg;
    border-color: $bd-element-border-color;

    &:hover {
        border-color: $bd-element-border-color;
        background-color: $bd-element-bg-hover;
    }
}

.page-item {
    &.disabled {
        .page-link {
            background-color: $bd-element-bg;
            border-color: $bd-element-border-color;
        }
    }
}

.progress {
    background-color: $bd-element-bg;
}